const operatorEndpoints = {
    getOperatorInfo: "/products/operatorInfo",
    getListOperator: "/royalprogram/getListOperators",
    subscribed: "/subscribe/subscribed",
    subscribe: "/subscribe/subscribe",
    unSubscribe: "/subscribe/unSubscribe",
    joined: "/royalprogram/joined",
    joinLoyalty: "/royalprogram/joinLoyalty",
    unjoinLoyalty: "/royalprogram/unjoinLoyalty",
  };
  
export default operatorEndpoints;