import { publicClient, privateClient } from '../../configs/axiosConfig';
import menuEndpoints from './menuEndpoints';

const menuApi = {
  getMenuCategory: async ({ operator }) => {
    try {
      const response = await publicClient.post(menuEndpoints.getMenuCategory, { operator });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Get operator failed");
    }
  },
  getMenuItems: async ({ operator }) => {
    try {
      const response = await publicClient.post(menuEndpoints.getMenuItems, { operator });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Get operator failed");
    }
  }
};

export default menuApi;