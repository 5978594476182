import { publicClient, privateClient } from '../../configs/axiosConfig';
import operatorEndpoints from './operatorEndpoints';

const operatorApi = {
  getOperatorInfo: async ({ operator }) => {
    try {
      const response = await publicClient.post(operatorEndpoints.getOperatorInfo, { operator });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Get operator failed");
    }
  },
  getListOperator: async (id) => {
    try {
      const response = await privateClient.post(operatorEndpoints.getListOperator, {userFrom: id});
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Get operator failed");
    }
  },
  subscribed: async (id) => {
    try {
      const response = await privateClient.post(operatorEndpoints.subscribed, {userFrom: id});
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Subscribe failed");
    }
  },
  subscribe: async (id) => {
    try {
      const response = await privateClient.post(operatorEndpoints.subscribe, {userFrom: id});
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Subscribe failed");
    }
  },
  unSubscribe: async (id) => {
    try {
      const response = await privateClient.post(operatorEndpoints.unSubscribe, {userFrom: id});
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Unsubscribe failed");
    }
  },
  joined: async (id) => {
    try {
      const response = await privateClient.post(operatorEndpoints.joined, {userFrom: id});
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Join failed");
    }
  },
  joinLoyalty: async (id) => {
    try {
      const response = await privateClient.post(operatorEndpoints.joinLoyalty, {userFrom: id});
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Join failed");
    }
  },
  unjoinLoyalty: async (id) => {
    try {
      const response = await privateClient.post(operatorEndpoints.unjoinLoyalty, {userFrom: id});
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Unjoin failed");
    }
  },
};

export default operatorApi;