import React from "react";

const Loading: React.FC = () => {
  return (
    <section className="flex flex-col items-center justify-center gap-12 w-full">
      <div className="h-[100px] w-[100px] border-[7px] border-red-500 rotate-45 rounded-[50%_50%_0%_50%] relative animate-move mb-8">
        <div className="absolute h-[55%] w-[55%] rounded-full border-[7px] border-transparent border-t-red-500 border-b-red-500 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-rotate"></div>
      </div>
      <h1 style={{ marginTop: "1rem" }}>Lokalai loading ... </h1>
    </section>
  );
};

export default Loading;