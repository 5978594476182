import React from "react";

interface RoyaltyCardBackProps {
  rewardDetails: string;
  terms: string;
  contactInfo: string;
  cardColor: string;
}

const RoyaltyCardBack: React.FC<RoyaltyCardBackProps> = ({
  rewardDetails,
  terms,
  contactInfo,
  cardColor,
}) => {
  return (
    <div
      className="rounded-3xl shadow-md p-6 w-80 h-[500px] flex flex-col justify-start items-center"
      style={{ backgroundColor: cardColor }}
    >
      <div className="bg-white rounded-3xl p-4 text-gray-800 w-full mt-4">
        <h3 className="font-bold text-center mb-2">REWARD DETAILS</h3>
        <p className="text-center text-sm mb-4">{rewardDetails}</p>
        <h4 className="font-semibold mb-2">TERM & CONDITIONS</h4>
        <p className="text-xs">{terms}</p>
        <p className="text-xs font-semibold mt-4 text-center">Contact: {contactInfo}</p>
      </div>
    </div>
  );
};

export default RoyaltyCardBack;
