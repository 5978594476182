import { publicClient, privateClient } from '../../configs/axiosConfig';
import couponItemEndpoints from './couponItemEndpoints';

const couponItemApi = {
  getCouponItem: async ({ _id }) => {
    try {
      const response = await publicClient.post(couponItemEndpoints.getCouponItems, { _id });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Get coupon item failed");
    }
  },

  getCouponById: async ({ id }) => {
    try {
      const response = await publicClient.get(couponItemEndpoints.getCouponById.replace(":id", id));
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || "Get coupon by id failed");
    }
  },
};

export default couponItemApi;