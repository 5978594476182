import React, { FC, Fragment, useState } from "react";
import { useEffect } from "react";
import Loading from "components/Loading/Loading";
import { RootState } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import QRCode from "qrcode";


export interface QRCodePageProps {
    className?: string;
}

const QRCodePage: FC<QRCodePageProps> = ({ className = "" }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [srcUser, setSrcUser] = useState("");
  const userId = user?._id;
  useEffect(() => {
    QRCode.toDataURL(userId).then(setSrcUser);
  }, []);
  return (
    <div className={`nc-QRCodePage ${className} flex justify-center flex-col`} data-nc-id="QRCodePage">
        <div className="flex justify-center items-center mt-6">
            <h1 className='text-4xl'><b className="text-red-500">Welcome</b>, {user?.displayName}</h1>
        </div>
        <div className="flex justify-center items-center mt-10 flex-col">
            <h1 className='text-4xl font-bold'>Your Unit QR code</h1>
            <h1 className='text-lg'>to receive Stamp Reward in Royalty Program</h1>
        </div>
        <img src={srcUser} style={{ margin: "0 auto", minWidth: "300px"}} />
    </div>
  );
}

export default QRCodePage;

