import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { FC, Fragment, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet-async";
import "./style.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Base CSS for Swiper
import "swiper/css/navigation"; // Optional - for navigation buttons
import "swiper/css/pagination"; // Optional - for pagination
import { CiFacebook } from "react-icons/ci";
import { CiInstagram } from "react-icons/ci";
import { CiYoutube } from "react-icons/ci";
import { IoQrCodeOutline } from "react-icons/io5";
import { FaRegAddressCard } from "react-icons/fa";
import { TiTags } from "react-icons/ti";
import { useParams } from "react-router";
import { useEffect } from "react";
import operatorApi from "api/operator/operator.api";
import { Link } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import chatApi from "api/chat/chat.api";
import { set } from "lodash";


export interface AuthorPageProps {
  className?: string;
}

export interface Operator {
  _id: string;
  name?: string;
  email?: string;
  password?: string;
  isAdmin?: boolean;
  coverPhoto?: string;
  photo?: string;
  phone?: string;
  address?: string;
  bio?: string;
  quote?: string;
  author?: string;
  __v?: number;
  createdAt?: string;
  updatedAt?: string;
}

interface SubscribeInfo {
  _id: string;
  userFrom: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}


const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [categories] = useState(["QR Menu", "Loyalty", "Best Deals"]);
  const [isLoading, setIsLoading] = useState(true);
  const [subscribed, setSubscribed] = useState(false);
  const [joined, setJoined] = useState(false);

  const category = [
    { name: "QR Menu", icon: <IoQrCodeOutline /> },
    { name: "Loyalty", icon: <FaRegAddressCard /> },
    { name: "Best Deals", icon: <TiTags /> },
  ];
  const [operator, setOperator] = useState<Operator[]>([]);
  const { id } = useParams<{ id: string }>();
  const getOperator = async () => {
    try {
        const response = await operatorApi.getOperatorInfo({ operator: id });
        if (response.success && response.operator) {
          const items: Operator[] = response.operator.map((item: any) => ({
            _id: item._id,
            name: item.name,
            email: item.email,
            password: item.password,
            isAdmin: item.isAdmin,
            coverPhoto: item.coverPhoto,
            photo: item.photo,
            phone: item.phone,
            address: item.address,
            bio: item.bio,
            quote: item.quote,
          }));
          setOperator(items);
        }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getSubscribed = async () => {
    try {
        const response = await operatorApi.subscribed(user?._id);
        setSubscribed(response.subcribed);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getJoined = async () => {
    try {
        const response = await operatorApi.joined(user?._id);
        setJoined(response.joined);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    getOperator();
    getSubscribed();
    getJoined();
    setIsLoading(false);
  }, []);

  const reviews = [
    {
      name: "John Doe",
      review: "This is the best service I have ever used. Highly recommend!",
      rating: 5,
      avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    },
    {
      name: "Jane Smith",
      review: "Amazing experience, customer service was top-notch.",
      rating: 4,
      avatar: "https://randomuser.me/api/portraits/women/2.jpg",
    },
    {
      name: "Michael Brown",
      review: "I am very satisfied with the quality of the product.",
      rating: 5,
      avatar: "https://randomuser.me/api/portraits/men/3.jpg",
    },
  ];

  const handleMessageSubmit = async () => {
    const groupTitle = `${operator[0].name} & ${user?.displayName}`;
    const userId = user?._id;
    const sellerId = operator[0]._id;
  
    try {
      const response = await chatApi.createNewConversation({
        groupTitle,
        userId,
        sellerId,
      });
  
      console.log("Conversation created:", response);
      navigate(`/userInbox`);
    } catch (error) {
      console.error("Failed to create new conversation:", error);
    }
  };

  const handleFollow = async () => {
    if (subscribed) {
      try {
        const response = await operatorApi.unSubscribe(user?._id);
        setSubscribed(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      try {
        const response = await operatorApi.subscribe(user?._id);
        setSubscribed(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }

  const handleJoin = async () => {
    if (joined) {
      try {
        const response = await operatorApi.unjoinLoyalty(user?._id);
        setJoined(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      try {
        const response = await operatorApi.joinLoyalty(user?._id);
        setJoined(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }

  const renderSidebar = () => {
    return (
      <>
          <div
            className="relative h-screen lg:h-[80vh] bg-cover bg-center"
            style={{
              backgroundImage: `url(${operator[0]?.coverPhoto})`, // Sử dụng ảnh nền từ operator hoặc ảnh mặc định
            }}
          >
          <div className="absolute inset-0 bg-neutral-950 bg-opacity-70 clear-filter before:hidden after:hidden"></div>
          <div className="absolute inset-0 bg-gradient-to-t from-black bg-neutral-950 to-blue-950 opacity-60"></div>
          {/* <div className="absolute inset-0 custom-gradient"></div> */}
          <div className="relative flex flex-col items-center justify-center text-center space-y- lg:space-y-12 py-12 lg:py-20 relative">
            <div className="w-40 h-40 rounded-full overflow-hidden border-4 border-white mt-40 sm:mt-20">
              <span
                className={` bg-teal-500 rounded-full text-white text-xs flex items-center justify-center absolute `}
              >
                <i className="las la-check"></i>{" "}
              </span>
              <img
                src={operator[0]?.photo}
                className="w-full h-full object-cover"
              />
            </div>
            <h3 className="text-4xl font-bold text-white mt-10 px-4">
              {operator[0]?.name || "Loading..."}
            </h3>
            <p className="text-lg text-gray-300">Street food</p>

            <div className="flex space-x-8 mt-4">
              <div className="text-center">
                <h2 className="text-2xl font-bold text-white">26</h2>
                <p className="text-gray-300">Comments</p>
              </div>
              <div className="text-center">
                <h2 className="text-2xl font-bold text-white">26</h2>
                <p className="text-gray-300">Comments</p>
              </div>
              <div className="text-center">
                <h2 className="text-2xl font-bold text-white">48</h2>
                <p className="text-gray-300">Bookmarks</p>
              </div>
            </div>
            <div className="space-y-4 mt-11">
              <div className="flex items-center space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-neutral-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
                <span className="text-gray-300 dark:text-neutral-300">
                  Ha Noi, Viet Nam
                </span>
              </div>
              <div className="flex items-center space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-neutral-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                  />
                </svg>
                <span className="text-gray-300 dark:text-neutral-300">
                  Speaking English
                </span>
              </div>

              <div className="flex items-center space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-neutral-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
                <span className="text-gray-300 dark:text-neutral-300">
                  Joined in March 2016
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center -mt-8 ">
          <button 
            className="btn-round bg-red-500 hover:bg-red-600 text-white text-lg px-8 py-3 rounded-full z-10 neon-black-box"
            onClick={handleFollow}
          >
            {subscribed ? "Followed" : "Follow"}
          </button>
          
          <button 
            className="btn-round bg-yellow-500 hover:bg-yellow-600 text-white text-lg px-8 py-3 rounded-full z-10 neon-black-box"
            onClick={handleJoin}
          >
            {joined ? "Joined" : "Join"}
          </button>

          {/* Hiển thị nút Message chỉ khi đã Join và Subscribe */}
          {joined && subscribed && (
            <button
              onClick={handleMessageSubmit}
              className="btn-round bg-blue-500 hover:bg-blue-600 text-white text-lg px-8 py-3 rounded-full z-10 neon-black-box"
            >
              Message
            </button>
          )}

          <button className="bg-neutral-400 hover:bg-neutral-600 text-white p-2 rounded-full z-10 -mt-">
            <CiFacebook size={30} color="#fff" />
          </button>
          <button className="bg-neutral-400 hover:bg-neutral-600 text-white p-2 rounded-full z-10 -mt-">
            <CiInstagram size={30} color="#fff" />
          </button>
          <button className="bg-neutral-400 hover:bg-neutral-600 text-white p-2 rounded-full z-10 -mt-">
            <CiYoutube size={30} color="#fff" />
          </button>
        </div>
        <h3 className="text-3xl font-bold text-center mt-6">About us</h3>
        <h5 className="text-lg text-gray-500 text-center max-w-lg mx-auto mt-4">
            {operator[0]?.bio || "Be Bau is a street food vendor in Hanoi, Vietnam. We serve the best street food in town. Come and enjoy our delicious food."}
        </h5>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8"></div>
      </>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap_no ">
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-3xl font-bold text-center mt-6">
            Be Bau's listings
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Be Bau's service is very rich, 5 star reviews help him to be more
            branded.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex overflow-x-auto justify-center">
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`rounded-full p-2 w-16 h-16 flex items-center justify-center rounded-full focus:outline-none my-4 mx-2 ${
                      selected
                        ? "bg-red-500 text-secondary-50 shadow-lg neon-black-box"
                        : "bg-neutral-200 text-neutral-600 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    } `}
                  >
                    <IoQrCodeOutline  size={20}/>
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`rounded-full p-2 w-16 h-16 flex items-center justify-center rounded-full focus:outline-none my-4 mx-2 ${
                      selected
                        ? "bg-red-500 text-secondary-50 neon-black-box"
                        : "bg-neutral-200 text-neutral-600 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    } `}
                  >
                    <FaRegAddressCard  size={20}/>
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`rounded-full p-2 w-16 h-16 flex items-center justify-center rounded-full focus:outline-none my-4 mx-2 ${
                      selected
                        ? "bg-red-500 text-secondary-50 neon-black-box"
                        : " bg-neutral-200 text-neutral-600 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    } `}
                  >
                    <TiTags size={20}/>
                  </button>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="">
                  <p>
                    Inspired by Loklai, Royal Vietnamese Café & Restaurant food menu is light, sunny and made for snacking, sharing or savouring solo. Fresh, seasonal and sustainable, the menu showcases suppliers and provenance and seafood is the star.
                    View the full dynamic menu 
                    <Link to={`/operator/menu/${id}`} className="text-blue-700 underline font-bold"> here</Link>
                  </p>
                </div>
          

                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_STAY_LISTINGS.filter((_, i) => i < 4).map((stay) => (
                    <StayCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 4).map(
                    (stay) => (
                      <ExperiencesCard key={stay.id} data={stay} />
                    )
                  )}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_CAR_LISTINGS.filter((_, i) => i < 4).map((stay) => (
                    <CarCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap_no">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {/* <CommentListing hasListingTitle className="pb-8" />
          <CommentListing hasListingTitle className="py-8" />
          <CommentListing hasListingTitle className="py-8" />
          <CommentListing hasListingTitle className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div> */}
          <div className="max-w-6xl mx-auto p-8">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              loop={true}
              autoplay={{ delay: 3000 }}
              // className="mySwiper"
              breakpoints={{
                // when window width is >= 640px (small devices)
                640: {
                  slidesPerView: 1,
                },
                // when window width is >= 1024px (large devices)
                1024: {
                  slidesPerView: 2,
                },
              }}
            >
              {reviews.map((review, index) => (
                <SwiperSlide key={index}>
                  <section className="bg-white dark:bg-gray-900">
                    <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                      <figure className="max-w-screen-md mx-auto">
                        <svg
                          className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
                          viewBox="0 0 24 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                            fill="currentColor"
                          />
                        </svg>
                        <blockquote>
                          <p className="text-2xl font-medium text-gray-900 dark:text-white">
                            {review.review}
                          </p>
                        </blockquote>
                        <figcaption className="flex items-center justify-center mt-6 space-x-3">
                          <img
                            className="w-6 h-6 rounded-full"
                            src={review.avatar}
                            alt="profile picture"
                          />
                          <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                            <div className="pr-3 font-medium text-gray-900 dark:text-white">
                              {review.name}
                            </div>
                            <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                              CEO at Google
                            </div>
                          </div>
                          <p className="text-yellow-500">
                            {"★".repeat(review.rating)}{" "}
                            {"☆".repeat(5 - review.rating)}
                          </p>
                        </figcaption>
                      </figure>
                    </div>
                  </section>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Login || Booking React Template</title>
      </Helmet>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Loading />
        </div>
      ) : (
        <main className="mt-0 mb-24 lg:mb-32 flex flex-col item-center justify-center">
          <div className="block flex-grow mb-24 lg:mb-0">
            <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
          </div>
          <div className="container space-y-8 lg:space-y-10 flex-shrink-0">
            {renderSection1()}
            {renderSection2()}
          </div>
        </main>
      )}
    </div>
  );
};

export default AuthorPage;
