import { update } from "lodash";

const userEndpoints = {
    signin: "v1/user/signin",
    signup: "v1/user/signup",
    getInfo: "v1/user/info",
    updatePassword: "v1/user/password/update",
    updateProfile: "v1/user/userUpdate",
    getListCardDesign: "royalprogram/getListCardDesign",
  };
  
export default userEndpoints;
  