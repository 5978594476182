import React, { FC, useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Input from "shared/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/authSlice";
import { AppDispatch, RootState } from "../../redux/store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import Logo from "shared/Logo/Logo";

interface LoginModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const LoginModal: FC<LoginModalProps> = ({ isOpen, closeModal }) => {
  const dispatch: AppDispatch = useDispatch();
  const { isLoading, error, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      closeModal();
    }
  }, [isAuthenticated, navigate, closeModal]);

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(login({ username: email, password }));
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                {/* <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Login to your account
                </Dialog.Title> */}
                <div className="h-auto w-auto flex justify-center items-center">
                    <Logo />
                </div>
                <div className="mt-4">
                  {error && <p className="text-red-500">{error}</p>}

                  <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin}>
                    <label className="block">
                      <span className="text-neutral-800">Email address</span>
                      <Input
                        type="email"
                        placeholder="example@example.com"
                        className="mt-1"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </label>
                    <label className="block">
                      <span className="text-neutral-800">Password</span>
                      <Input
                        type="password"
                        className="mt-1"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </label>

                    <ButtonPrimary type="submit" disabled={isLoading}>
                      {isLoading ? "Logging in..." : "Login"}
                    </ButtonPrimary>
                  </form>
                </div>

                <div className="mt-4 text-center">
                  <p>
                    New user?{" "}
                    <Link to="/signup" onClick={closeModal}>
                      Create an account
                    </Link>
                  </p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoginModal;