import React from "react";

interface RoyaltyCardFrontProps {
  storeName: string;
  description: string;
  stamps: number;
  maxStamps: number;
  stampImage: string;
  cardColor: string;
}

const RoyaltyCardFront: React.FC<RoyaltyCardFrontProps> = ({
  storeName,
  description,
  stamps,
  maxStamps,
  stampImage,
  cardColor,
}) => {
  return (
    <div className="rounded-3xl shadow-xl w-80 h-[500px] flex flex-col items-center">

      {/* Header */}
      <div className="w-full h-2/5 bg-black rounded-t-3xl relative overflow-hidden">
        <div
          className="absolute mt-12 inset-x-0 top-[-30px] mx-auto w-16 h-16 bg-cover bg-center rounded-full border-4 border-white"
          style={{ backgroundImage: `url(${stampImage})` }}
        />

        <div className="pt-10 mt-12 pb-4 text-center text-white">
          <h2 className="font-bold text-xl tracking-wide">{storeName}</h2>
          <p className="text-sm">{description}</p>
        </div>

        <svg
          className="absolute bottom-[-1px] left-0 right-0"
          viewBox="0 0 1440 320"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill={cardColor}
            d="M0,96L48,80C96,64,192,32,288,64C384,96,480,192,576,202.7C672,213,768,139,864,122.7C960,107,1056,149,1152,144C1248,139,1344,85,1392,58.7L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>

      {/* Body */}
      <div className="flex flex-col h-3/5 items-center p-4 justify-between rounded-b-3xl" style={{ backgroundColor: cardColor }}>
        <div className="grid grid-cols-5 gap-2 mb-4">
          {Array.from({ length: maxStamps }).map((_, index) => (
            <div
              key={index}
              className={`w-10 h-10 rounded-full flex items-center justify-center ${
                index < stamps ? "bg-white" : "bg-gray-200"
              }`}
            >
                <img src={`${process.env.PUBLIC_URL}/static/media/coffee.png`} alt="Stamp" className="w-6 h-6" />
            </div>
          ))}
        </div>

        <p className="text-center text-sm text-white">
          For every single-receipt purchase of $20, get a stamp to collect rewards!
        </p>
      </div>
    </div>
  );
};

export default RoyaltyCardFront;
