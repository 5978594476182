import React from "react";
import MyRouter from "routers/index";
import io from "socket.io-client";

const ENDPOINT = "https://socket-lokalai-23jul.onrender.com/";
// const ENDPOINT = "http://localhost:6005/";

const socketId = io(ENDPOINT, { transports: ["websocket"] });

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter socketId={socketId} />
    </div>
  );
}

export default App;
